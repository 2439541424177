import { changeLoading } from '@src/actions/appActions'
import { i18n } from '@src/lib/i18n'
import { NextPage } from 'next'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

const HomePage: NextPage = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    // Display the loading screen
    dispatch(changeLoading({ isShow: true, message: i18n.t('loading') }))

    return () => {
      dispatch(changeLoading({ isShow: false }))
    }
  }, [])

  return <></>
}

export default HomePage
